


























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Role, Permission } from '@/store/modules/role'
import { Product } from '@/store/modules/product'
import { Market } from '@/store/modules/market'

@Component
export default class ManageRole extends Vue {
  @Prop() role!: Role
  selectedPermissions: string[] = []
  selectedMarkets: string[] = []
  selectedProducts: string[] = []

  get permissionOptions () {
    return this.$store.getters.getPermissions
      .sort((a: Permission, b: Permission) => { return a.name < b.name ? -1 : a.name > b.name ? 1 : 0 })
  }

  get marketOptions () {
    return this.$store.getters.getMarkets
  }

  get productOptions () {
    return this.$store.getters.getProducts
  }

  modalShown () {
    this.selectedPermissions = this.role.permissions.map((p: Permission) => {
      return p.key
    })
    this.selectedMarkets = this.role.markets.map((p: Market) => {
      return p.key
    })
    this.selectedProducts = this.role.products.map((p: Product) => {
      return p.key
    })
  }

  permissionsChanged () {
    this.role.permissions = this.permissionOptions.filter((p: Permission) => {
      return this.selectedPermissions.includes(p.key)
    })
  }

  marketsChanged () {
    this.role.markets = this.marketOptions.filter((p: Market) => {
      return this.selectedMarkets.includes(p.key)
    })
  }

  productsChanged () {
    this.role.products = this.productOptions.filter((p: Product) => {
      return this.selectedProducts.includes(p.key)
    })
  }

  deleteRole () {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to delete?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      this.$store.dispatch('deleteRole', this.role).then(() => {
        this.$bvModal.hide('manage-role-modal')
      })
    })
  }

  saveChanges () {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to save changes?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      this.$store.dispatch('saveRole', this.role).then(() => {
        this.$bvModal.hide('manage-role-modal')
      })
    })
  }
}
