








































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Permission } from '@/store/modules/role'

@Component
export default class ManagePermissionsModal extends Vue {
  @Prop() permissions!: Permission[]
  newPermissionName: string = ''
  newPermissionDesc: string = ''

  resetFields () {
    this.newPermissionName = ''
    this.newPermissionDesc = ''
  }

  @Emit('addPermission')
  addPermission () {
    const newPermission: any = {
      name: this.newPermissionName,
      description: this.newPermissionDesc
    }
    this.resetFields()
    return newPermission
  }

  @Emit('removePermission')
  removePermission (permissionKey: string) {
    return permissionKey
  }
}
