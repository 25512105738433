



































































import { Component, Vue } from 'vue-property-decorator'
import { Role, Permission } from '@/store/modules/role'
import ManageRoleModal from './ManageRole.modal.vue'
import ManagePermissionsModal from './ManagePermissions.modal.vue'

@Component({
  components: {
    ManageRoleModal,
    ManagePermissionsModal
  }
})
export default class RolesAdminDashboard extends Vue {
  helpText: string = 'Here you can create roles and assign permissions for actions, markets and products to them.'
  breadcrumbItems: any[] = [
    {
      text: 'Admin',
      to: { name: 'admin' }
    },
    {
      text: 'Manage roles',
      to: { name: 'manage-roles' }
    }
  ]

  activeRole: Role = this.$store.getters.getRoleByKey()

  get roles () {
    return this.$store.getters.getRoles
  }

  get permissions () {
    return JSON.parse(JSON.stringify(this.$store.getters.getPermissions))
      .sort((a: Permission, b: Permission) => { return a.name < b.name ? -1 : a.name > b.name ? 1 : 0 })
  }

  sortPermissions (permissions: Permission[]) {
    return JSON.parse(JSON.stringify(permissions))
      .sort((a: Permission, b: Permission) => { return a.name < b.name ? -1 : a.name > b.name ? 1 : 0 })
  }

  mounted () {
    this.$store.dispatch('loadRoles')
    this.$store.dispatch('loadPermissions')
    this.$store.dispatch('loadMarkets')
    this.$store.dispatch('loadProductsIncDisabled')
  }

  openManageRoleModal (key: string) {
    this.activeRole = this.$store.getters.getRoleByKey(key)
    this.$bvModal.show('manage-role-modal')
  }

  manageRoleModalClosed () {
    this.$store.dispatch('loadRoles')
  }

  addNewRole () {
    this.$bvModal.msgBoxConfirm('Confirm add a new role?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      this.$store.dispatch('addRole').then((newRole: Role) => {
        this.$store.dispatch('loadRoles')
      })
    })
  }

  openManagePermissionsModal (key: string) {
    this.$bvModal.show('manage-permissions-modal')
  }

  managePermissionsModalClosed () {
    this.$store.dispatch('loadPermissions')
  }

  removePermission (key: string) {
    if (!key) {
      return
    }
    this.$bvModal.msgBoxConfirm('Confirm remove this permission?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      this.$store.dispatch('deletePermission', key).then(() => {
        this.$store.dispatch('loadPermissions')
      })
    })
  }

  addNewPermission (newPermission: Permission) {
    if (!newPermission.name) {
      return
    }
    this.$bvModal.msgBoxConfirm('Confirm add new permission?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      this.$store.dispatch('addPermission', newPermission).then((newPermission: Permission) => {
        this.$store.dispatch('loadPermissions')
      })
    })
  }
}
